<template>
  <div class="step1-page">
    <!-- <oAddress :addressArr.sync="addressArr"></oAddress> -->
    <oProduct ref="product" :goods="form.goods"></oProduct>
    <oBalance
      v-if="form.member_info && form.member_info.available_predeposit"
      :balance="form.member_info.available_predeposit"
      :totalPrice="form.total_price"
      :balancePayState.sync="balancePayState"
    ></oBalance>
    <!-- <oVouchers></oVouchers> -->
    <oPay
      @errorEmit="errorEmit"
      :len="len"
      :form="form"
      :cartForm="cartForm"
      :balancePayState="balancePayState"
    ></oPay>
    <!-- <oRemarks> </oRemarks> -->
  </div>
</template>

<script>
import oAddress from "../buy/components/address";
import oProduct from "./components/product.vue";
import oBalance from "./components/balance";
import oPay from "./components/pay";
import { post } from "@get/http";

export default {
  components: {
    oAddress,
    oProduct,
    oBalance,
    oPay,
  },
  data() {
    return {
      addressArr: [],
      remarks: "", //用户备注
      form: {}, //请求的数据
      cartForm: null, //购物车数据
      voucher: {}, //代金券
      len: 0, //产品数量
      balancePayState: false, //是否零钱支付
      errorText: "",
    };
  },
  async created() {
    let data = await post(
      "/v3.MemberPresaleGoods/previewPresaleGoodsDepositOrder",
      {
        params: {
          goods_id: this.$route.query.goods_id,
        },
      }
    );
    (this.cartForm = this.$route.query.goods_id), (this.form = data);
  },
  methods: {
    errorEmit(data) {
      this.errorText = data;
    },
  },
  mounted() {
    // console.log(this.$refs.product.$refs);
  },
};
</script>

<style lang="scss" scoped>
@import "~@css/mixin.scss";

.step1-page {
  margin-top: rem(24);
}
</style>
